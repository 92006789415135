@font-face {
  font-family: "GT Walsheim Pro Regular";
  src: local("GT Walsheim Pro Regular"),
    url("./assets/fonts/GT\ Walsheim\ Pro\ Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim Pro Bold Regular";
  src: local("GT Walsheim Pro Bold Regular"),
    url("./assets/fonts/GT\ Walsheim\ Pro\ Bold\ Regular.ttf")
      format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  box-sizing: border-box;
  overflow-y: hidden;
  font-family: "GT Walsheim Pro Regular" !important;
}
.logo {
  height: 15vh;
}
.logo-image {
  height: 80px !important;
}
form {
  height: 70vh;
}
.form-header,
.password-requirement,
.message {
  color: #4b36a5;
}
.form-header h2,
.message-head h1 {
  font-family: "GT Walsheim Pro Bold Regular";
}
.form-header p {
  font-size: 14px;
}
footer {
  height: 10vh;
}
footer a {
  font-size: 14px;
}
.password,
.submit-btn {
  width: 320px;
}
.submit-btn {
  border-radius: 20px !important;
  color: #4b36a5 !important;
}
.container-fluid {
  height: 100vh !important;
}
input::placeholder {
  font-size: 14px;
}
.message {
  height: 100vh;
}
@media (max-width: 350px) {
  form,
  .password,
  .submit-btn {
    width: 280px;
  }
  .logo {
    height: 15vh;
  }
  .logo-image {
    height: 60px !important;
  }
  form {
    height: 65vh;
  }
  footer {
    height: 15vh;
  }
}
@media (max-height: 640px) {
  .logo-image {
    height: 60px !important;
  }
}
